import { createTheme } from '@mui/material/styles';

export default class Theme {
  static defaultTheme = createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          rows: 4,
          variant: 'outlined',
        },
      },
    },
  });
}
