import { Application, Attachment, ClosedPeriod, User } from "../models";

export default class Mapping {
  static convertToApplication(applicationData) {
    const application = new Application();
    application.id = applicationData?.id || '';
    application.fullName = applicationData?.fullName || '';
    application.jobTitle = applicationData?.jobTitle || '';
    application.address = applicationData?.address || '';
    application.cprNumber = applicationData?.cprNumber || '';
    application.bankRegNumber = applicationData?.bankRegNumber || '';
    application.bankAccountNumber = applicationData?.bankAccountNumber || '';
    application.nameOfSpouse = applicationData?.nameOfSpouse || '';
    application.childrenLivingAtHome = applicationData?.childrenLivingAtHome || '';
    application.healthDescription = applicationData?.healthDescription || '';
    application.additionalRemarks = applicationData?.additionalRemarks || '';
    application.reasonForApplication = applicationData?.reasonForApplication || '';
    application.familyName = applicationData?.familyName || '';
    application.familyRelations = {
      happe: applicationData.familyRelations.happe,
      kinderballe: applicationData.familyRelations.kinderballe,
    };
    application.secondGeneration = applicationData?.secondGeneration || '';
    application.thirdGeneration = applicationData?.thirdGeneration || '';
    application.fourthGeneration = applicationData?.fourthGeneration || '';
    application.fifthGeneration = applicationData?.fifthGeneration || '';
    application.sixthGeneration = applicationData?.sixthGeneration || '';
    application.seventhGeneration = applicationData?.seventhGeneration || '';
    application.mothersBirthday = applicationData?.mothersBirthday ? new Date(applicationData?.mothersBirthday) : new Date();
    application.fathersBirthday = applicationData?.fathersBirthday ? new Date(applicationData?.fathersBirthday) : new Date();
    application.additionalInformation = applicationData?.additionalInformation || '';
    application.attachments = applicationData?.attachments?.map(attachmentResponse => this.convertToAttachment(attachmentResponse));
    application.createdDate = applicationData?.createdDate ? new Date(applicationData?.createdDate) : new Date();
    return application;
  }

  static convertToAttachment(attachmentData) {
    const attachment = new Attachment();
    attachment.id = attachmentData.id;
    attachment.name = attachmentData.name;
    return attachment;
  }

  static convertToUser(userData) {
    const user = new User();
    user.userIdentifier = userData.userIdentifier;
    user.roles = userData.roles;
    return user;
  }

  static convertToClosedPeriod(periodData) {
    const period = new ClosedPeriod();
    period.startDate = periodData.startDate;
    period.endDate = periodData.endDate;
    period.isClosed = periodData.isClosed;
    return period;
  }
}
