import { Delete, Download, InsertDriveFile, Upload } from "@mui/icons-material";
import { Alert, AlertTitle, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Api } from '../../utils';
import { Attachment } from "../../models";

const Attachments = ({
  uploadedFiles = [],
  setUploadedFiles = () => { },
  isReadOnly = false
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadErrored, setDownloadErrored] = useState(false);
  const fileInput = useRef(null);

  const handleButtonEvent = (_event) => {
    fileInput.current.click();
  }

  const handleFileEvent = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    addFiles(chosenFiles);
  }

  const addFiles = (filesToAdd) => {
    const uploaded = [...uploadedFiles];
    for (const addedFile of filesToAdd) {
      if (uploaded.findIndex(file => file.name === addedFile.name) === -1) {
        const attachment = new Attachment();
        attachment.name = addedFile.name;
        attachment.inputFile = addedFile;
        uploaded.push(attachment);
      }
    }
    setUploadedFiles(uploaded);
  }

  const removeAttachment = (file) => {
    const newFiles = uploadedFiles.filter(f => f.name !== file.name);
    setUploadedFiles(newFiles);
  }

  const downloadAttachment = (file) => {
    setIsDownloading(true);
    Api.downloadAttachment(file.id).then(response => {
      setDownloadErrored(false);
    }).catch(error => {
      setDownloadErrored(true);
    }).finally(() => {
      setIsDownloading(false);
    });
  }

  const AttachmentLine = ({
    file,
    isReadOnly = false,
  }) => {
    return (
      <ListItem>
        <ListItemAvatar>
          <InsertDriveFile color="info" />
        </ListItemAvatar>
        <ListItemText primary={file.name} sx={{ flexGrow: 'unset' }} />
        {!isReadOnly && (
          <IconButton
            onClick={() => removeAttachment(file)}
            sx={{ marginLeft: 2 }}
            title='Fjern bilag'
            color='primary'
          >
            <Delete />
          </IconButton>
        )}
        {file.id && (
          <IconButton
            variant=''
            disabled={isDownloading}
            onClick={() => downloadAttachment(file)}
            sx={{ marginLeft: 2 }}
            title='Hent bilag'
            color='primary'
          >
            <Download />
          </IconButton>
        )}
      </ListItem>
    );
  }

  return (
    <React.Fragment>
      {!isReadOnly && (
        <Button onClick={handleButtonEvent} endIcon={<Upload />}>Vælg filer</Button>
      )}
      {uploadedFiles.length > 0 ? (
        <List>
          {uploadedFiles.map((file, index) => (
            <AttachmentLine key={index} file={file} isReadOnly={isReadOnly} />
          ))}
        </List>
      ) : (
        <Typography variant="body1" marginY={2}>
          {isReadOnly ? "Der er ikke tilføjet nogle filer." : "Valgte filer vil vises her."}
        </Typography>
      )}
      {downloadErrored && !isDownloading && (
        <Alert severity='error' >
          <AlertTitle>Der skete en fejl</AlertTitle>
          Filen kunne ikke hentes fra serveren.
        </Alert>
      )}
      <input
        ref={fileInput}
        id="attachmentUpload"
        style={{ display: "none" }}
        type='file'
        onChange={handleFileEvent}
        multiple />
    </React.Fragment>
  );
}

export default Attachments;
