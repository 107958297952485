import { OpenInNew } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from 'react';
import { Api } from '../../utils';
import { ApplicationForm, Loader } from "../../components";
import { Application } from "../../models";

function Administration() {
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    fetchApplications();
  }, []);

  /**
   * Fetches a list of applications from the server.
   */
  const fetchApplications = () => {
    setIsLoading(true);
    Api.listApplications().then(applications => {
      setApplications(applications);
      setIsLoading(false);
      setIsErrored(false);
    }).catch(error => {
      setApplications([]);
      setIsLoading(false);
      setIsErrored(true);
    });
  }

  return <ApplicationList
    applications={applications}
    isLoading={isLoading}
    isErrored={isErrored} />;
}

function ApplicationList({
  applications,
  isLoading,
  isErrored,
}) {
  const [isShowingDetails, setIsShowingDetails] = useState(false);
  const [activeApplication, setActiveApplication] = useState(null);
  const [isLoadingApplication, setIsLoadingApplication] = useState(false);
  const [loadingApplicationFailed, setLoadingApplicationFailed] = useState(false);

  const showApplication = (application) => {
    fetchApplicationById(application.id);
    setIsShowingDetails(true);
  }

  const fetchApplicationById = (applicationId) => {
    setIsLoadingApplication(true);
    Api.getApplication(applicationId).then(application => {
      setActiveApplication(application);
      setIsLoadingApplication(false);
      setLoadingApplicationFailed(false);
    }).catch(error => {
      setActiveApplication(null);
      setIsLoadingApplication(false);
      setLoadingApplicationFailed(true);
    });
  }

  return (
    <Container>
      <ApplicationModal
        application={activeApplication}
        isLoading={isLoadingApplication}
        isErrored={loadingApplicationFailed}
        isOpen={isShowingDetails}
        onClose={() => setIsShowingDetails(false)}
        onExited={() => setActiveApplication(null)}
      />
      <Typography variant='h4' marginY={2} align='center'>Ansøgninger</Typography>
      {isLoading ? (
        <Loader />
      ) : isErrored ? (
        <Alert severity="error">
          <AlertTitle>Der skete en fejl</AlertTitle>
          Ansøgningerne kunne ikke indlæses.
        </Alert>
      ) : (
        <Paper>
          <TableContainer >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Navn</TableCell>
                  <TableCell align="center">Happe</TableCell>
                  <TableCell align="center">Kinderballe</TableCell>
                  <TableCell align="right">Dato for ansøgning</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.fullName}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox readOnly disableRipple color="primary" checked={row.familyRelations.happe} sx={{ cursor: 'default' }} />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox readOnly disableRipple color="primary" checked={row.familyRelations.kinderballe} sx={{ cursor: 'default' }} />
                    </TableCell>
                    <TableCell align="right">{row.createdDate.toLocaleString('da-DK')}</TableCell>
                    <TableCell align="right">
                      <Button endIcon={<OpenInNew />} onClick={() => showApplication(row)}>
                        Vis
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Container>
  )
}

const ApplicationModal = ({
  application = new Application(),
  isLoading = false,
  isErrored = false,
  isOpen = false,
  onClose = () => {},
  onExited = () => {},
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='md'
      TransitionProps={{
        onExited: onExited,
      }}
    >
      <DialogTitle>Ansøgning</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loader />
        ) : isErrored ? (
          <Alert severity="error">
            <AlertTitle>Der skete en fejl</AlertTitle>
            Ansøgningen kunne ikke indlæses.
          </Alert>
        ) : (
          <ApplicationForm
            application={application}
            attachments={application?.attachments}
            isReadOnly />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Luk</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Administration;
