import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CriiptoVerifyProvider } from '@criipto/verify-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
// Use special adapter for compatibility with date-fns v3.x.
// https://mui.com/x/react-date-pickers/adapters-locale/#with-date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from './utils';
import { da } from 'date-fns/locale';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CriiptoVerifyProvider
      domain={process.env.REACT_APP_CRIIPTO_DOMAIN}
      clientID={process.env.REACT_APP_CRIIPTO_CLIENT_ID}
      redirectUri={process.env.REACT_APP_CRIIPTO_REDIRECT_URI}
      sessionStore={window.localStorage}
    >
      <ThemeProvider theme={Theme.defaultTheme}>
        <LocalizationProvider adapterLocale={da} dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </CriiptoVerifyProvider>
  </React.StrictMode>
);
