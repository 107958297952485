import { Container } from '@mui/system';
import { Link, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Container sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
      <Typography variant="subtitle1">Egil og Maja Christiansens Familielegat-Fond</Typography>
      <Typography variant="body2">v/administrator advokat Maria G. Erlang Schaumann</Typography>
      <Typography variant="body2">Tinghusgade 43, 5700 Svendborg</Typography>
      <Typography sx={{ mt: 4 }} variant="body2">
        <Link href="/betingelser.pdf" target="_blank">Behandling af personfølsomme oplysninger</Link>
      </Typography>
    </Container>
  );
}

export default Footer;
