import { Navigate } from 'react-router-dom';
import { Roles } from '../../models';
import Loader from '../Loader';

const ProtectedRoute = ({ roles, requiredRole = Roles.Admin, children }) => {
  if (!roles) {
    return <Loader />
  }

  if (requiredRole && !roles.some(role => role === requiredRole)) {
    return <Navigate to="/" />
  }

  return children;
}

export default ProtectedRoute;
