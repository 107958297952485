import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';

const Closed = ({
  startDate,
  endDate,
  dateFormat = 'do LLLL',
}) => {
  const formattedStartDate = format(new Date(startDate), dateFormat, { locale: da });
  const formattedEndDate = format(new Date(endDate), dateFormat, { locale: da });

  return (
    <Container>
      <Typography variant='h6' marginTop={8} marginBottom={4} align='center'>Lukket for ansøgninger</Typography>
      <Typography variant='body1' marginTop={8} marginBottom={4} align='center'>
        Der er i øjeblikket lukket for ansøgninger.
      </Typography>
      <Typography variant='body1' marginTop={4} marginBottom={8} align='center'>
        Der er åbent for ansøgninger i perioden fra {formattedStartDate} til {formattedEndDate}.
      </Typography>
    </Container>
  );
}

export default Closed;
