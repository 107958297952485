import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Attachments, Loader } from '../../components';
import { Application, FamilyRelations } from '../../models';
import React, { useState } from 'react';
import { Send } from '@mui/icons-material';

const ApplicationForm = ({
  application = new Application(),
  setApplication = () => { },
  attachments = [],
  setAttachments = () => { },
  submitApplication = () => { },
  isReadOnly = false,
  isLoading = false,
  isErrored = false,
  setIsErrored = () => { },
  errors = [],
}) => {
  const [formError, setFormError] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  /**
   * Handles changes from from input fields. Updates the application field with the same name as the name attribute on
   * the input field. If validation errors has previously been found, the form will be validated again.
   * @param {React.ChangeEvent} event The event fired from the form element.
   */
  const handleChange = (event) => {
    changeValue(event.target.name, event.target.value);
  }

  /**
   * Handles changes to family relation.
   * @param {React.ChangeEvent} event The event fired from the form element.
   */
  const handleFamily = (event) => {
    const { name, id, checked } = event.target
    const changedRelations = new FamilyRelations();
    changeValue(name, Object.assign(changedRelations, application.familyRelations, { [id]: checked }));
  }

  const changeValue = (field, value) => {
    if (isReadOnly) {
      console.error(`Field ${field} was changed in read only mode.`);
      return;
    }
    const updatedApplication = {
      ...application,
      [field]: value,
    };
    setApplication(updatedApplication);
    if (formError) {
      formIsValid(updatedApplication);
    }
  }

  /**
   * Handler for the submit button. Validates the form and calls the submitApplication handler if the form is valid.
   */
  const submitForm = () => {
    setIsErrored(false);
    if (isReadOnly) {
      console.error('Form was submitted in read only mode.');
    } else if (formIsValid(application, true)) {
      submitApplication();
    } else {
      // console.debug('Validation errors found')
    }
  }

  /**
   * Validates the application form and saves validation errors.
   * @param {Application} applicationData Application model to validate.
   * @param {boolean} scrollToFirstError If true, scroll to the first field with an error. Defaults to false.
   * @returns True if the application form is valid, otherwise false.
   */
  const formIsValid = (applicationData, scrollToFirstError = false) => {
    const validationResult = getValidationErrors(applicationData);
    const hasErrors = !!Object.keys(validationResult).length;
    if (hasErrors && scrollToFirstError) {
      const firstFieldNameWithError = Object.keys(validationResult)[0];
      scrollToField(firstFieldNameWithError);
    }
    setValidationErrors(validationResult);
    setFormError(hasErrors);
    return !hasErrors;
  }

  /**
   * Validates the application and returns error messages for invalid fields.
   * @param {Application} applicationData Application model to validate.
   * @returns An object with validation error messages.
   */
  const getValidationErrors = (applicationData) => {
    const newValidationErrors = {};
    for (const property in applicationData) {
      if (Object.hasOwnProperty.call(applicationData, property)) {
        const value = applicationData[property];
        const validation = applicationData.validations[property];
        if (validation && !validation.condition(value)) {
          newValidationErrors[property] = validation.message;
        }
      }
    }
    return newValidationErrors;
  }

  /**
   * Scroll to the field with a name attribute matching the given field name.
   * @param {string} fieldName The name of the field to scroll to.
   */
  const scrollToField = (fieldName) => {
    if (fieldName) {
      const fieldElement = document.querySelector(`[name="${fieldName}"]`);
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.error(`Tried to scroll to field '${fieldName}', but no element with a matching name attribute was found.`);
      }
    }
  }

  if (!application) {
    return <p>Ingen ansøgning</p>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' marginBottom={1}>Ansøger</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='fullName'
            label='Navn'
            required
            error={formError && !!validationErrors['fullName']}
            helperText={formError && validationErrors['fullName']}
            value={application.fullName}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='jobTitle'
            label='Stilling'
            required
            error={formError && !!validationErrors['jobTitle']}
            helperText={formError && validationErrors['jobTitle']}
            value={application.jobTitle}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='address'
            label='Adresse'
            required
            error={formError && !!validationErrors['address']}
            helperText={formError && validationErrors['address']}
            value={application.address}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name='cprNumber'
            label='CPR-nr'
            required
            error={formError && !!validationErrors['cprNumber']}
            helperText={formError && validationErrors['cprNumber']}
            value={application.cprNumber}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name='bankRegNumber'
            label='Reg-nr'
            required
            error={formError && !!validationErrors['bankRegNumber']}
            helperText={formError && validationErrors['bankRegNumber']}
            value={application.bankRegNumber}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <TextField
            name='bankAccountNumber'
            label='Konto-nr'
            required
            error={formError && !!validationErrors['bankAccountNumber']}
            helperText={formError && validationErrors['bankAccountNumber']}
            value={application.bankAccountNumber}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
      </Grid>


      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' marginBottom={1}>Samlivsform</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={formError && !!validationErrors['nameOfSpouse']}>
            <RadioGroup
              row
              name="nameOfSpouse"
              value={application.nameOfSpouse}
              onChange={handleChange}>
              <FormControlLabel
                value="Gift"
                control={<Radio readOnly={isReadOnly} />}
                label="Gift" />
              <FormControlLabel
                value="Samboende"
                control={<Radio readOnly={isReadOnly} />}
                label="Samboende" />
              <FormControlLabel
                value="Enlig"
                control={<Radio readOnly={isReadOnly} />}
                label="Enlig" />
            </RadioGroup>
            <FormHelperText>{formError && validationErrors['nameOfSpouse']}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' marginBottom={1}>Familieforhold</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='childrenLivingAtHome'
            label='Hjemmeboende børn + alder'
            error={formError && !!validationErrors['childrenLivingAtHome']}
            helperText={formError && validationErrors['childrenLivingAtHome']}
            multiline
            value={application.childrenLivingAtHome}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
      </Grid>


      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' marginBottom={1}>Detaljer</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='healthDescription'
            label='Helbredsmæssig tilstand'
            error={formError && !!validationErrors['healthDescription']}
            helperText={formError && validationErrors['healthDescription']}
            multiline
            value={application.healthDescription}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='additionalRemarks'
            label='Særlige bemærkninger f.eks. vedrørende økonomi eller helbred'
            error={formError && !!validationErrors['additionalRemarks']}
            helperText={formError && validationErrors['additionalRemarks']}
            multiline
            value={application.additionalRemarks}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='reasonForApplication'
            label='Legat søges til følgende formål'
            required
            error={formError && !!validationErrors['reasonForApplication']}
            helperText={formError && validationErrors['reasonForApplication']}
            multiline
            value={application.reasonForApplication}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
      </Grid>


      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' marginBottom={1}>Slægtsforhold</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl name="familyRelations" error={formError && !!validationErrors['familyRelations']}>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox name="familyRelations" id="happe" checked={application.familyRelations.happe} onChange={handleFamily} readOnly={isReadOnly} />}
                label="Jeg tilhører Happe-slægten" />
              <FormControlLabel
                control={<Checkbox name="familyRelations" id="kinderballe" checked={application.familyRelations.kinderballe} onChange={handleFamily} readOnly={isReadOnly} />}
                label="Jeg tilhører Kinderballe-slægten" />
            </FormGroup>
            <FormHelperText>{formError && validationErrors['familyRelations']}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body2'>
            Der skal altid redegøres for samtlige forfædre tilbage til Hans J.J. Happe og hustru eller Mads Hansen og hustru. Derudover skal førstegangsansøgere vedlægge kopi af dåbsattest.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name='secondGeneration'
            label='2. generation'
            required
            error={formError && !!validationErrors['secondGeneration']}
            helperText={formError && validationErrors['secondGeneration']}
            value={application.secondGeneration}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='thirdGeneration'
            label='3. generation'
            required
            error={formError && !!validationErrors['thirdGeneration']}
            helperText={formError && validationErrors['thirdGeneration']}
            value={application.thirdGeneration}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='fourthGeneration'
            label='4. generation'
            required
            error={formError && !!validationErrors['fourthGeneration']}
            helperText={formError && validationErrors['fourthGeneration']}
            value={application.fourthGeneration}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='fifthGeneration'
            label='5. generation'
            error={formError && !!validationErrors['fifthGeneration']}
            helperText={formError && validationErrors['fifthGeneration']}
            value={application.fifthGeneration}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='sixthGeneration'
            label='6. generation'
            error={formError && !!validationErrors['sixthGeneration']}
            helperText={formError && validationErrors['sixthGeneration']}
            value={application.sixthGeneration}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='seventhGeneration'
            label='7. generation'
            error={formError && !!validationErrors['seventhGeneration']}
            helperText={formError && validationErrors['seventhGeneration']}
            value={application.seventhGeneration}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Typography variant='subtitle2'>Mine forældres fødselsdage</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            name="mothersBirthday"
            label="Moders fødselsdag"
            required
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date()}
            value={application.mothersBirthday}
            onChange={newValue => changeValue('mothersBirthday', newValue)}
            readOnly={isReadOnly}
            slotProps={{
              textField: {
                error: formError && !!validationErrors['mothersBirthday'],
                helperText: formError && validationErrors['mothersBirthday'],
                required: true,
              }
            }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            name="fathersBirthday"
            label="Faders fødselsdag"
            required
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date()}
            value={application.fathersBirthday}
            onChange={newValue => changeValue('fathersBirthday', newValue)}
            readOnly={isReadOnly}
            slotProps={{
              textField: {
                error: formError && !!validationErrors['fathersBirthday'],
                helperText: formError && validationErrors['fathersBirthday'],
                required: true,
              }
            }} />
        </Grid>
      </Grid>


      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' marginBottom={1}>Bilag</Typography>
        </Grid>
        <Grid item xs={12}>
          <Attachments
            uploadedFiles={attachments}
            setUploadedFiles={setAttachments}
            isReadOnly={isReadOnly} />
        </Grid>
      </Grid>


      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' marginBottom={1}>Bemærkninger/oplysninger</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='additionalInformation'
            label='Øvrige bemærkninger/oplysninger'
            error={formError && !!validationErrors['additionalInformation']}
            helperText={formError && validationErrors['additionalInformation']}
            multiline
            value={application.additionalInformation}
            onChange={handleChange}
            InputProps={{
              readOnly: isReadOnly,
            }} />
        </Grid>
      </Grid>


      {!isReadOnly && (
        <Stack marginY={6} alignItems='center' spacing={2}>
          <Button
            size='large'
            onClick={submitForm}
            endIcon={<Send />}
            disabled={isLoading}
          >
            Indsend ansøgning
          </Button>
          {isLoading && (
            <Loader />
          )}
          {formError ? (
            <Alert severity='warning' >
              <AlertTitle>Der er valideringsfejl</AlertTitle>
              Tjek at alle felter er udfyldt.
            </Alert>
          ) : isErrored && (
            <Alert severity='error' >
              <AlertTitle>Der skete en fejl</AlertTitle>
              {errors?.length > 0 ? (
                <ul>
                  {errors?.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              ) : (
                <p>Der skete en fejl under kommunikation med serveren.</p>
              )}
            </Alert>
          )}
        </Stack>
      )}

    </React.Fragment>
  );
}

export default ApplicationForm;
