import { Download, Logout } from "@mui/icons-material";
import { Alert, AppBar, IconButton, Popover, Snackbar, Toolbar, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from "react";
import { Roles } from "../../models";
import { Api } from '../../utils';

function Header({
  user,
  roles = [],
  logout = () => console.error('No logout handler provided.'),
}) {
  const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "info",
    message: "",
  });

  const isAdministrator = () => roles?.some(role => role === Roles.Admin);

  const openPopover = (event) => {
    setPopoverAnchorElement(event.currentTarget)
  }

  const closePopover = () => {
    setPopoverAnchorElement(null);
  }

  const exportApplications = () => {
    setIsExporting(true);
    Api.exportApplications().then(response => {
      showSnackBar("Ansøgninger blev eksporteret til Excel", "info");
    }).catch(error => {
      showSnackBar("Der skete en fejl og ansøgningerne kunne ikke eksporteres", "error");
    }).finally(() => {
      setIsExporting(false);
    });
  }

  const showSnackBar = (message, severity) => {
    setSnackbarState({
      open: true,
      severity: severity,
      message: message,
    })
  }

  const closeSnackBar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  }

  const open = Boolean(popoverAnchorElement);
  const id = open ? 'simple-popover' : undefined;

  const UserPopover = () => {
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={popoverAnchorElement}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="body2" padding={2}>
          {user?.uuid}
        </Typography>
      </Popover>
    );
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>Egil og Maja Christiansens Familielegat-Fond</Typography>
        {user && roles && (
          <React.Fragment>
            <Typography variant="caption" onClick={openPopover} sx={{ cursor: 'pointer' }}>
              {`${user.name}${isAdministrator() ? ' (Administrator)' : ''}`}
            </Typography>
            <UserPopover />
            {isAdministrator() && (
              <LoadingButton sx={{ ml: 2 }} color="inherit" variant="outlined" startIcon={<Download />} onClick={exportApplications} loading={isExporting} loadingPosition="start">
                Eksporter
              </LoadingButton>
            )}
            <IconButton size="large" color="inherit" onClick={logout} sx={{ ml: 2 }}>
              <Logout />
            </IconButton>
          </React.Fragment>
        )}
      </Toolbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: 8 }}
        open={snackbarState.open}
        autoHideDuration={4000}
        onClose={closeSnackBar}>
        <Alert severity={snackbarState.severity}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </AppBar>
  );
}

export default Header;
