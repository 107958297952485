import axios from "axios"
import Mapping from "../utils/mapping";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('@criipto-verify-react/session');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

const ApiRoutes = {
  Application: '/application',
  Attachment: '/attachment',
  User: '/user',
}

export default class Api {

  static async getUserInfo() {
    try {
      const response = await axios.get(ApiRoutes.User);
      const user = Mapping.convertToUser(response.data);
      return user;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async isClosedPeriod() {
    try {
      const response = await axios.get(`${ApiRoutes.Application}/closed`);
      const period = Mapping.convertToClosedPeriod(response.data);
      return period;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async submitApplication(application) {
    try {
      await axios.post(ApiRoutes.Application, application);
    } catch (error) {
      throw error?.response?.data;
    }
  }

  static async getApplicationForUser(uuid) {
    try {
      const response = await axios.get(`${ApiRoutes.Application}${ApiRoutes.User}/${uuid}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async listApplications() {
    try {
      const response = await axios.get(ApiRoutes.Application);
      const applications = response.data.map(applicationData => Mapping.convertToApplication(applicationData));
      return applications;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async getApplication(applicationId) {
    try {
      const response = await axios.get(`${ApiRoutes.Application}/${applicationId}`);
      const application = Mapping.convertToApplication(response.data);
      return application;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async downloadAttachment(attachmentId) {
    try {
      const response = await axios.get(`${ApiRoutes.Attachment}/${attachmentId}`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename = this.getFileName(response, attachmentId);
      link.setAttribute('download', filename);
      link.setAttribute('href', url);
      link.click();
      window.URL.revokeObjectURL(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async exportApplications() {
    try {
      const response = await axios.get(`${ApiRoutes.Application}/export`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename = this.getFileName(response, 'export.xlsx');
      link.setAttribute('download', filename);
      link.setAttribute('href', url);
      link.click();
      window.URL.revokeObjectURL(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static getFileName(fileBlob, defaultFileName) {
    const contentDisposition = fileBlob.headers['content-disposition'];
    if (contentDisposition) {
      const fileNameIdentifier = 'filename=';
      const fileNamePosition = contentDisposition.indexOf(fileNameIdentifier);
      if (fileNamePosition >= 0) {
        const endQuotePosition = contentDisposition.indexOf(';', fileNamePosition + fileNameIdentifier.length);
        const endPosition = endQuotePosition >= 0 ? endQuotePosition : contentDisposition.length - 1;
        const result = contentDisposition.slice(fileNamePosition + fileNameIdentifier.length, endPosition);
        return result.replaceAll('"', '');
      }
    }
    return defaultFileName;
  }
}
