import { Typography } from '@mui/material';
import { useState } from 'react';
import { ApplicationForm, Closed, Loader } from '../../components';
import { Application, ClosedPeriod, FamilyRelations } from '../../models';
import { Api } from '../../utils';
import { Container } from '@mui/system';

function ApplicationPage({
  user,
  closedPeriod = new ClosedPeriod(),
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [application, setApplication] = useState(new Application());
  const [hasApplied, setHasApplied] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [errors, setErrors] = useState([]);

  // useEffect(() => {
  //   fetchUserApplication();
  // }, []);

  // /**
  //  * Fetches the application made by the current user from the server. If the current user has already applied,
  //  * a hasApplied flag will be set.
  //  */
  // const fetchUserApplication = () => {
  //   setIsLoading(true);
  //   Api.getApplicationForUser(user.uuid).then(application => {
  //     setHasApplied(!!application);
  //     setIsLoading(false);
  //   });
  // }

  /**
   * Submits the application to the server. Constructs a FormData object and appends form fields and attachment files
   * to it before sending the request, then a hasApplied flag will be set, which is used to inform the user that the
   * application is sent.
   */
  const submitApplication = () => {
    const formData = new FormData();
    appendApplication(formData);
    appendFiles(formData);
    setIsLoading(true);
    Api.submitApplication(formData).then(response => {
      setHasApplied(true);
      setIsErrored(false);
      setErrors([]);
      resetForm();
    }).catch(error => {
      setIsErrored(true);
      const combinedErrors = readErrors(error?.errors)
      setErrors(combinedErrors);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  /**
   * Converts an object with validation errors from the backend to an array with error messages.
   * @param {Object} errors Object with validation errors from backend.
   * @returns An array with error messages.
   */
  const readErrors = (errors) => {
    const combinedErrors = [];
    for (const property in errors) {
      if (Object.hasOwnProperty.call(errors, property)) {
        const propertyErrors = errors[property];
        combinedErrors.push(...propertyErrors);
      }
    }
    return combinedErrors;
  }

  /**
   * Appends the fields from the current application into a FormData object.
   * @param {FormData} formData The object into which to append the application data.
   */
  const appendApplication = (formData) => {
    for (const property in application) {
      if (Object.hasOwnProperty.call(application, property)) {
        const value = application[property];
        if (value instanceof Date) {
          formData.append(property, value.toISOString());
        } else if (value instanceof FamilyRelations) {
          Object.keys(value).forEach(familyName => {
            formData.append(familyName, value[familyName]);
          });
        } else {
          formData.append(property, value);
        }
      }
    }
  }

  /**
   * Appends the chosen attachment files into a FormData object.
   * @param {FormData} formData The object into which to append the files.
   */
  const appendFiles = (formData) => {
    attachments.forEach(attachment => {
      formData.append('attachments', attachment.inputFile);
    });
  }

  /**
   * Resets the form fields to their default values and removes attachments.
   */
  const resetForm = () => {
    setApplication(new Application());
    setAttachments([]);
  }

  return isLoading ? (
    <Loader />
  ) : closedPeriod?.isClosed ? (
    <Closed startDate={closedPeriod.startDate} endDate={closedPeriod.endDate} />
  ) : hasApplied ? (
    <Container>
      <Typography variant='h4' marginY={2} align='center'>Tak for din ansøgning</Typography>
      <Typography variant='body1' marginY={8} align='center'>
        Din ansøgning er modtaget og vil blive behandlet snarest.
      </Typography>
      <Typography variant='body1' marginY={8} align='center'>
        Bemærk at du ikke vil modtage en bekræftelse via email.
      </Typography>
    </Container>
  ) : (
    <Container>
      <Typography variant='h4' marginY={2} align='center'>Ansøgningsskema</Typography>
      <Typography variant='subtitle2' marginY={2} align='center'>
        Skal være administrator i hænde senest den 31. oktober
      </Typography>

      <Typography variant='body1' marginY={2} align='justify'>
        Undertegnede ansøger om legatportion fra ovennævnte legat. Jeg er bekendt med at legatet alene uddeles til
        descendenter efter skipper Hans Jokum Johansen Happe, Bagenkop og hustru Oline, født Gotfredsen eller gårdejer
        i Kinderballe, Langeland, Mads Hansen og hustru Johanne Madsdatter. Legatet ydes fortrinsvis til hjælp til
        uddannelse, etablering eller brudeudstyr samt hjælp i sygdomstilfælde og til rekreation eller huslejehjælp.
      </Typography>

      <Typography variant='body1' sx={{ fontWeight: 'bold' }} marginY={2} align='center'>
        Førstegangsansøgere skal vedlægge kopi af dåbsattest. I alle tilfælde oplyses om slægtsforhold fuldt ud.
      </Typography>

      <ApplicationForm
        application={application}
        setApplication={setApplication}
        attachments={attachments}
        setAttachments={setAttachments}
        submitApplication={submitApplication}
        isLoading={isLoading}
        isErrored={isErrored}
        setIsErrored={setIsErrored}
        errors={errors}
      />
    </Container>
  );
}

export default ApplicationPage;
