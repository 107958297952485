import { useCriiptoVerify } from '@criipto/verify-react';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Api } from './utils';
import { Administration, ApplicationPage, Login, NotFound } from './pages';
import { Footer, Header, Loader, ProtectedRoute } from './components';
import { Roles } from './models';
import { Container } from '@mui/system';

function App() {
  const { claims, isLoading, logout, error } = useCriiptoVerify();
  const [roles, setRoles] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isUserErrored, setIsUserErrored] = useState(false);
  const [closedPeriodLoading, setClosedPeriodLoading] = useState(false);
  const [closedPeriod, setClosedPeriod] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (claims) {
      setIsUserLoading(true);
      Api.getUserInfo().then(user => {
        setRoles(user.roles ? user.roles : []);
        setIsUserErrored(false);
        if (user?.roles.some(role => role === Roles.Admin)) {
          navigate('/administration')
        }
      }).catch(error => {
        setIsUserErrored(true);
        setRoles(null);
      }).finally(() => {
        setIsUserLoading(false);
      });
    }
  }, [claims, navigate]);

  useEffect(() => {
    setClosedPeriodLoading(true);
    Api.isClosedPeriod().then(closedPeriod => {
      setClosedPeriod(closedPeriod);
    }).catch(error => {
      setIsUserErrored(true);
    }).finally(() => {
      setClosedPeriodLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Header user={claims} roles={roles} logout={logout} />
      <Container>

        {isLoading || isUserLoading || closedPeriodLoading ? (
          <Loader />
        ) : claims && roles ? (
          <Routes>
            <Route path="/administration" element={
              <ProtectedRoute roles={roles}>
                <Administration />
              </ProtectedRoute>
            } />
            <Route path="/" element={<ApplicationPage user={claims} closedPeriod={closedPeriod} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <React.Fragment>
            {error ? (
              <p>
                An error occured: {error.error} ({error.error_description}). Please try again:
              </p>
            ) : null}
            <Login closedPeriod={closedPeriod} />
            {isUserErrored && (
              <Alert severity="error">
                <AlertTitle>Der skete en fejl</AlertTitle>
                Der skete en fejl under kommunikation med serveren.
              </Alert>
            )}
          </React.Fragment>
        )}

      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default App;
