import { Stack } from '@mui/system';
import { CircularProgress } from '@mui/material';

const Loader = () => (
  <Stack alignItems="center" marginY={16}>
    <CircularProgress size={80} />
  </Stack>
);

export default Loader;
