import { FamilyRelations } from '.';

class Application {
  fullName = '';
  jobTitle = '';
  address = '';
  cprNumber = '';
  bankRegNumber = '';
  bankAccountNumber = '';
  nameOfSpouse = '';
  childrenLivingAtHome = '';
  healthDescription = '';
  additionalRemarks = '';
  reasonForApplication = '';
  secondGeneration = '';
  thirdGeneration = '';
  fourthGeneration = '';
  fifthGeneration = '';
  sixthGeneration = '';
  seventhGeneration = '';
  additionalInformation = '';
  familyRelations = new FamilyRelations();
  mothersBirthday = null;
  fathersBirthday = null;
  attachments = [];

  validations = ({
    fullName: {
      condition: (value) => !!value,
      message: 'Venligst udfyld fulde navn',
    },
    jobTitle: {
      condition: (value) => !!value,
      message: 'Venligst udfyld stilling',
    },
    address: {
      condition: (value) => !!value,
      message: 'Venligst udfyld adresse',
    },
    cprNumber: {
      condition: (value) => !!value && value.match(/^([0-9]{10}|[0-9]{6}-[0-9]{4})$/),
      message: 'Venligst udfyld et gyldigt CPR-nr',
    },
    bankRegNumber: {
      condition: (value) => !!value,
      message: 'Venligst udfyld reg-nr',
    },
    bankAccountNumber: {
      condition: (value) => !!value,
      message: 'Venligst udfyld konto-nr',
    },
    familyRelations: {
      condition: (value) => Object.values(value).some(isSelected => isSelected),
      message: 'Vælg venligst dine slægtsforhold',
    },
    reasonForApplication: {
      condition: (value) => !!value,
      message: 'Udfyld venligst formål med ansøgning',
    },
    secondGeneration: {
      condition: (value) => !!value,
      message: 'Der skal redegøres for alle generationer indtil 4. generation',
    },
    thirdGeneration: {
      condition: (value) => !!value,
      message: 'Der skal redegøres for alle generationer indtil 4. generation',
    },
    fourthGeneration: {
      condition: (value) => !!value,
      message: 'Der skal redegøres for alle generationer indtil 4. generation',
    },
    mothersBirthday: {
      condition: (value) => !!value && !isNaN(value) && value >= new Date(1900, 0, 1) && value <= new Date(),
      message: 'Der skal udfyldes en gyldig dato',
    },
    fathersBirthday: {
      condition: (value) => !!value && !isNaN(value) && value >= new Date(1900, 0, 1) && value <= new Date(),
      message: 'Der skal udfyldes en gyldig dato',
    },
  });
}

export default Application;
