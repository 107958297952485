import { AuthMethodSelector } from "@criipto/verify-react";
import { Container } from '@mui/system';
import { Box, Typography } from '@mui/material';
import '@criipto/verify-react/dist/criipto-verify-react.css';
import { ClosedPeriod } from "../../models";
import { Closed } from "../../components";

function Login({
  closedPeriod = new ClosedPeriod(),
}) {
  return (
    <Container>
      {closedPeriod?.isClosed ? (
        <Closed startDate={closedPeriod.startDate} endDate={closedPeriod.endDate} />
      ) : (
        <Typography variant="h6" align="center" marginY={8}>
          For at ansøge skal du logge ind med MitID
        </Typography>
      )}
      <Box display='flex' justifyContent='center'>
        <AuthMethodSelector language="da" />
      </Box>
    </Container>
  );
}

export default Login;
